<template>
  <div class="home-page-container">
    <el-container class="home-container">
      <v-aside />
      <el-container class="in-container page-content-margin-sm-and-up">

        <el-main class="scroll-content">
          <el-header>
            <v-header />
          </el-header>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import VHeader from '@/components/v-header'
import VAside from '@/components/v-aside'
import { mapState, mapActions } from 'vuex'
import MixinAllOptions from '@/mixins/all-options'
import { getAccountDetail } from '@/api/account'
import {gtTicketNotify} from '@api/ticket'
export default {
  name: 'HomeContainer',
  components: {
    VHeader,
    VAside
  },
  mixins: [MixinAllOptions],
  data() {
    return {
      theme_name:'',
      timer:''
    }
  },
  computed: {
    ...mapState('common', ['globalOptions','themeColor'])
  },
  watch:{
    themeColor:{
      immediate:true,
      handler(val){
        this.changeThemeColor(val)
      }
    }
  },
  mounted() {
    this.network().getAccountDetail()
    this.network().gtTicketNotify()
    this.timer = setInterval(() => {
      this.network().gtTicketNotify()
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions('common', ['setAccounterName','setThemeColor','setTicketNotify']),
    changeThemeColor(val='blue') {
      if(!val)
        val = 'blue'
      let theme_color={
        blue:{
          color:'#003EE5',
          bg:'DEF6FF'
        },
        purple:{
          color:'#6332A7',
          bg:'#F4EFFA',
        },
        green:{
          color:'#137C4A',
          bg:'#E7F5EC',
        },
        orange:{
          color:'#E9681B',
          bg:'#FFEDE2',
        }
      }
      let theme = theme_color[val]
      var root = document.documentElement
      root.style.setProperty('--primary-color',theme.color)
      root.style.setProperty('--primary-bg-color',theme.bg)
      root.style.setProperty('--primary-aside-bg-color',theme.bg)
    },
    network() {
      return {
        getAccountDetail: async () => {
          const { data } = await getAccountDetail()
          let {nickname='',alias='blue'} = data||{}
          this.setAccounterName(nickname)
          this.setThemeColor(alias)
          // this.theme_name = data.alias
        },
        gtTicketNotify: async () => {
          const { data } = await gtTicketNotify()
          this.setTicketNotify(data.unread)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page-container {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background:rgb(247,250,252);
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  .home-container{
    width: 100%;
    height: 100%;
  }
  .in-container {
    width: 100%;
    //height: calc(100% - 60px);
  }
  .el-header {
    padding: 0;
    z-index: 10;
    //width: 264px!important;
    height: auto!important;
  }
  .scroll-content{
    height: 100vh;
    -webkit-overflow-scrolling: touch;
  }

}
</style>
