import { render, staticRenderFns } from "./v-aside.vue?vue&type=template&id=703f33b5&scoped=true&"
import script from "./v-aside.vue?vue&type=script&lang=js&"
export * from "./v-aside.vue?vue&type=script&lang=js&"
import style0 from "./v-aside.vue?vue&type=style&index=0&id=703f33b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703f33b5",
  null
  
)

export default component.exports